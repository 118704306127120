import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  List,
  ListItem,
  makeStyles,
  TextField,
  Button,
  IconButton,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import { FaFacebookF } from "react-icons/fa";
import { ImLinkedin2 } from "react-icons/im";

import { } from "react-feather";
import Logo from "./../../component/Logo";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    position:"relative",
    overflow:"hidden",
    background: "rgba(32, 8, 59, 0.75)",
    backdropFilter: "blur(275.838px)",
    // background: "#000",
    // background: "rgba(255, 255, 255, 0.1)",
    // backdropFilter:" blur(101px)",
    // position: "relative",
    paddingTop: theme.spacing(4),
    paddingBottom: "32px",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
      // paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
      // paddingBottom: theme.spacing(2),
    },
    "& .socialLink":{
      padding: 0,
      width: "30px !important",
      height: "30px !important", 
      borderRadius: "50% !important",
      backgroundColor: "#1e0d47 !important",
      display: "flex !important",
      justifyContent: "center !important",
      alignItems: "center !important",
      textAlign: "center !important",
      marginRight:"5px",
      "&:hover":{
        "& svg":{
          color:"#fff",
        }, 
      },
      "& svg":{
        margin:"0",
        fontSize:"16px",
        color:"#fff",
      },
    },
    "& h5": {
      fontWeight: "bold",
      fontSize: "16px",
      letterSpacing: "2px",
      textTransform: "uppercase",
    },
    "& ul": {
      paddingLeft: "0",
    },
    "& p": {
      marginBottom: "20px",
      marginTop: "10px",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "18px",
    },
  },
  input_fild: {
    backgroundColor: "#e1e1e1",
    borderRadius: "50px",
    color: "#000",
    height: "55px",
    width: "100%",
    borderBottom: "none",
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
    },
    "& .MuiInputBase-input": {
      color: "#000",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
      borderWidth: 0,
    },
  },

  footerBg: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    left: "0",
  },
  ListItem: {
    paddingLeft: "0px",
  },
  borderBottmo: {
    overflow: "hidden",
    background: "#000",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  signupBtn: {
    color: "#fff",
    borderRadius: "10px",
    display: "flex",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    minWidth: "100px",
    borderRadius: "50px",
    position: "absolute",
    top: "5px",
    right: "5px",
    boxShadow:
      "0px 8px 24px rgba(38, 50, 56, 0.1), 0px 16px 32px rgba(38, 50, 56, 0.08)",
    lineHeight: "36px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: " 1px",
    background: "#040405",
    "&:hover": {
      background: "#040405",
      color: "#fff",
    },
  },
  largeIcon: {
    width: 18,
    height: 18,
    marginRight: "8px",
  },
  icons: {
    justify: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justify: "center",
    },
  },
  inputBox: {
    position: "relative",
  },
  socialIcon: {
    display: " flex",
    alignItems: "center",
    marginTop: "10px",
    "& svg": {
      color: "#aeadad",
      cursor: "pointer",
      marginRight: " 10px",
      "&:hover": {
        color: "#39076a",
      },
    },
  },

}));

export default function Liquidity() {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.footerSection}>
      <img src="/images/circle.svg" alt="" className="footerleft" />
      <img src="/images/circle.svg" alt="" className="footerright" />
        <Box>
          <Container maxWidth="lg">
            <Grid container justify="space-around" spacing={1}>
              <Grid item xs={12} md={3} lg={5}>
                <Box>
                  <Link to="/">
                    <Box display="flex" alignItems="center">
                      <Box>
                        <Logo className="logoImg" />
                      </Box>
                      <Box pl={1}>
                        <Typography
                          variant="h1"
                          style={{
                            color: "#fff",
                            fontSize: "40px",
                            letterSpacing: "10px",
                            fontWeight: "500",
                          }}
                        >
                          TIAR
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                </Box>
                {/* <Box className={classes.socialIcon}>
                  <TwitterIcon /> <TelegramIcon />
                  <FaFacebookF /> <ImLinkedin2 />
                </Box> */}
                <List className="listingFooter">
                  <ListItem>
                    <Link to="/profile">copyright 2021 | NFT Marketplace</Link>
                  </ListItem>
                </List>
              </Grid>

              <Grid item xs={6} md={2} lg={2}>
                <Typography variant="h5" style={{ color: "#fff" }}>
                  My Account
                </Typography>
                <List className="listingFooter">
                  <ListItem>
                    <Link to="/profile">My Profile</Link>
                  </ListItem>
                  <ListItem>
                    <Link to="/activity">My Activity</Link>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={3} lg={2}>
                <Typography variant="h5" style={{ color: "#fff" }}>
                  Terms Of Use
                </Typography>
                <List className="listingFooter">
                  <ListItem>
                    <Link to="/">Terms & Conditions</Link>
                  </ListItem>
                  <ListItem>
                    <Link to="/">Privacy Policy</Link>
                  </ListItem>
                  <ListItem>
                    <Link to="/">Cookies Policy</Link>
                  </ListItem>
                </List>
              </Grid>

              <Grid item xs={12} md={12} lg={2}>
                <Typography variant="h5" style={{ color: "#fff" }}>
                  Reach Us
                </Typography>

                {/* <Box mt={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box className={classes.inputBox}>
                        <TextField
                          placeholder="Your e-mail"
                          variant="filled"
                          color="secondary"
                          fullWidth
                          // className={classes.input_fild}
                        />
                        <Button className={classes.signupBtn}>I'm in</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box> */}
                <Box className={classes.socialIcon}>
                  <Link className="socialLink">
                    <TwitterIcon />
                  </Link>
                  <Link className="socialLink">
                    <TelegramIcon />
                  </Link>
                  <Link className="socialLink">
                    <FaFacebookF />
                  </Link>
                  <Link className="socialLink">
                    <ImLinkedin2 />
                  </Link>
                  {/* <TwitterIcon style={{ color: "#fff" }} />{" "}
                  <TelegramIcon style={{ color: "#fff" }} />
                  <FaFacebookF style={{ color: "#fff" }} />{" "}
                  <ImLinkedin2 style={{ color: "#fff" }} />
                  <TelegramIcon style={{ color: "#fff" }} />
                  <TwitterIcon style={{ color: "#fff" }} />{" "} */}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        {/* <img src="/images/circle.svg" alt="" className="fcircleAnimate" />
        <img src="/images/circle.svg" alt="" className="foocircleAnimate" /> */}
      </Box>
    </>
  );
}
