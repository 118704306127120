import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import HomeLayout from "src/layouts/HomeLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/About/AboutUs")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    exact: true,
    path: "/Search",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Search/Index")),
  },
  {
    exact: true,
    path: "/marketplace",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Marketplace/Index")),
  },
  {
    exact: true,
    path: "/activity",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Activity/index")),
  },
  {
    exact: true,
    path: "/nft-details",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/NftDetails/index")),
  },
  {
    exact: true,
    path: "/collection",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/NftDetails/Collection")),
  },
  // {
  //   exact: true,
  //   path: "/create",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/Create-NFT/Create")),
  // },
  {
    exact: true,
    path: "/create",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Create-NFT/CreateNFT")),
  },
  {
    exact: true,
    path: "/resell-nft",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Create-NFT/ResellNFT")),
  },
  {
    exact: true,
    path: "/user",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/UsersView/UsersView")),
  },
  {
    exact: true,
    path: "/profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Profile/Profile")),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Profile/EditProfile")),
  },
  {
    exact: true,
    path: "/wallet",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Wallet/index")),
  },
  // {
  //   exact: true,
  //   path: "/Metaarts-token",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/MetaartsToken/Index")),
  // },
  {
    exact: true,
    path: "/faq",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/FAQ")),
  },
  {
    exact: true,
    path: "/notification",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Notification")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
